// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Injectable } from '@angular/core';
import { Mapper } from './mapper';
import { CbdcType, Transaction, TransactionDetails, TransactionDto } from '../models/transaction';
import { Tools } from '@app/services/common/tools';

@Injectable()
export class TransactionMapper implements Mapper<Transaction, TransactionDto> {
  toModel(dto: TransactionDto): Transaction {
    const userEntity = Tools.getUserEntity();
    const hasRole = Tools.hasAppRole(Tools.roles.DL3SOPERATOR, Tools.roles.CASHSUPER);
    const isCreditedWalletClickable = 
      userEntity ===  dto.creditedCashWalletManagerID || 
      Tools.hasAppRole(Tools.roles.DL3SOPERATOR, Tools.roles.CASHSUPER)

    const isDebitedWalletClickable = 
      dto.debitedCashWalletManagerID === userEntity ||
      dto.onBehalfOwner === userEntity || // Do not have debitedCashWalletOwnerID so using onBehalfOwner instead for debited
      dto.onBehalfUser === userEntity || // Do not have debitedCashWalletOwnerID or onBehalfOwner so using onBehalfUser instead for debited
      Tools.hasAppRole(Tools.roles.DL3SOPERATOR, Tools.roles.CASHSUPER)

    return {
      id: dto.instructionLTID,
      status: dto.etatsUX,
      type: dto.cbdcRequestType.toLowerCase() as CbdcType === 'operation' ? dto.type : 'payment',
      currency: dto.currency,
      amount: dto.amountTransferred,
      creditedWalletId: {
        value: dto.creditedCashWalletAlias, 
        isClickable: isCreditedWalletClickable
      },
      creditedManagerId: dto.creditedCashWalletManagerID,
      debitedWalletId: {
        value: dto.debitedCashWalletAlias, 
        isClickable: isDebitedWalletClickable
      },
      debitedManagerId: dto.debitedCashWalletManagerID,
      statusTimestamp: dto.timestamps[dto.etatsUX].calendarDate,
      cbdcType: dto.cbdcRequestType as CbdcType,
      senderID: dto.senderID,
      instructingPartyID: dto.instructingPartyID,
      onBehalfOwner: dto.onBehalfOwner,
      onBehalfUser: dto.onBehalfUser,
      redeemCashWalletAlias: dto.redeemCashWalletAlias,
      debitedCashWalletManagerID: dto.debitedCashWalletManagerID,
      debitedT2AccountID: dto.debitedT2AccountID,
      debitedNetworkID: dto.debitedNetworkID,
      issuanceCashWalletAlias: dto.issuanceCashWalletAlias,
      creditedCashWalletManagerID: dto.creditedCashWalletManagerID,
      creditedNetworkID: dto.creditedNetworkID,
      creationDate: dto.creationDate,
      debitedCountryCode: dto.debitedCountryCode,
      creditedCountryCode: dto.creditedCountryCode,
      initiatorUserName: dto.initiatorUserName,
      approverUserName: dto.approverUserName,
      settlementType: dto.settlementType,
      ISD: dto.ISD,
      ISDTimestamp: dto.ISDTimestamp,
      supplementaryData: dto.supplementaryData,
      operationContext: dto.operationContext,
      poaID: dto.poaID,
      settlementDate: dto.settlementDate
    };
  }

  toModels(dtos: TransactionDto[]): Transaction[] {
    return dtos.map((dto) => this.toModel(dto));
  }

  toDetailsModel(dto: TransactionDto): TransactionDetails {
    const transactionDetails: TransactionDetails = this.toModel(dto);
    transactionDetails.timestamps = dto.timestamps;
    transactionDetails.historicStatus = dto.historicStatus;
    transactionDetails.initiator = dto.initiatorUserName;
    transactionDetails.statusRootCause = dto.etatsUXRootCause;

    return transactionDetails;
  }

  toDto(entity: Transaction): TransactionDto {
    throw new Error('Method not implemented.');
  }

  toDtos(entities: Transaction[]): TransactionDto[] {
    throw new Error('Method not implemented.');
  }
}
