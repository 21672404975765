import { Component, OnInit } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
import { ParametersService } from '@app/services/parameters/parameters.service';

@Component({
  selector: 'app-formly-ref-data-wrapper',
  templateUrl: './ref-data-wrapper.component.html',
  styleUrls: ['./ref-data-wrapper.component.scss']
})
export class RefDataWrapperComponent extends FieldWrapper implements OnInit {
  public referenceDataFields: FormlyFieldConfig[];
  private _businessDate: Date;

  constructor(private _parameterService: ParametersService) {
    super();
    this._parameterService.getBusinessDate().subscribe({
      next: (businessDate: string) => {
        this._businessDate = new Date(businessDate);
      }
    });
  }

  public ngOnInit(): void {
    this.referenceDataFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'validFrom',
            type: 'date',
            className: 'col-xs-12 col-sm-6',
            props: {
              placeholder: 'Valid From *',
              required: true,
              filter: this.field?.fieldGroup[0]?.key == "accountReference" ? false: this.validFromDateFilter,
              disabled: this.props.disableReferenceData
            }
          },
          {
            key: 'validTo',
            type: 'date',
            className: 'col-xs-12 col-sm-6',
            defaultValue: null,
            props: {
              placeholder: 'Valid To',
              filter: this.validToDateFilter,
              disabled: this.props.disableReferenceData
            }
          }
        ]
      }
    ];
  }

  public validFromDateFilter = (validFromDate: Date | null): boolean => {
    if (this._businessDate) {
      let validFromWithHours = new Date(validFromDate);
      let businessDateWithHours = new Date(this._businessDate);
      return validFromWithHours.setHours(0, 0, 0, 0) >= businessDateWithHours.setHours(0, 0, 0, 0);
    }
    return true;
  };

  public validToDateFilter = (validToDate: Date | null): boolean => {
    if (!this._businessDate) {
      return true;
    }
    if (this.model.validFrom) {
      const validFromDate: Date = new Date(this.model.validFrom);
      return validToDate >= validFromDate && validToDate >= this._businessDate;
    }
    return validToDate >= this._businessDate;
  };
}
