// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { TimeStamps } from '@core/timestamps';

// from IMS DTO, transform it to this model
// (only front for display list & detailed view)

export class LiquidityModel {

  public instructionLTID: string;
  public techCBDCOperationID: string;
  public senderID: string;
  public instructingPartyID: string;
  public onBehalfOwner: string;
  public onBehalfUser: string;
  public amountTransferred: number;
  public currency: string;
  public debitedCashWalletAlias: string;
  public debitedCashWalletManagerID: string;
  public debitedNetworkID: string;
  public creditedCashWalletAlias: string;
  public creditedCashWalletManagerID: string;
  public creditedNetworkID: string;
  public etatsUX: string;
  public acceptedTimestamp: Date;
  public creationDate: string;
  public type: string;
  public timestamps: TimeStamps;
  public historicStatus: string[];
  public initiatorUserName: string;
  public approverUserName: string;
  public etatsUXRootCause?: string;
  public operationContext?: string;
  public ISD?: Date | string;
  public supplementaryData?: string;
  public settledTimestamp?: string;
  public settledDate?: string;
  public cbdcRequestType?: string;
  public settlementType?: string;
  public ISDTimestamp?: number;
  public poaID?: string;

}
